<template>
  <h1 class="text-center">Flyout Menus</h1>
</template>

<script>
export default {
  name: "FlyoutMenus"
}
</script>

<style scoped>

</style>